import React,{useState} from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import emailjs, { init } from 'emailjs-com';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';

init("user_kTFRcYLOOyBzElMLQZHRu");

const Box = styled.div`
  margin-right: 20px;
`;
const Container = styled.div`
  width: 50%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  .image-icon{
    height: 31px;
    width: 31px;
  }
  .image-aseo{
    height: 232px;
    width: 242px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }
  @media(max-width: 1000px){
    width: 100%;
  }
`;
const FormContainer = styled.form`
  // max-width: 440px;
  // width: 100%;
  background: white;
  border: 1px solid rgba(0, 17, 51, 0.15);
  border-radius: 24px;
  // padding: 28px;
  padding: 18px 54px 18px 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // margin-top: 70px;
  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: rgba(0, 17, 51, 0.8);
    align-self: center;
    margin-top: 0;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0, 17, 51, 0.6);
  }
  span{
    color:#FF3355;
    margin-bottom: 23px;
    min-height: 22px;
  }
  label{
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: rgba(0, 17, 51, 0.8); 
    margin-bottom: 5px;
  }
  input {
    // height: 48px;
    width: 100%;
    border: 1px solid rgba(0, 17, 51, 0.15);
    border-radius: 6px;
    // margin-bottom: 23px;
    padding: 13px;
    color: rgba(0, 17, 51, 0.8);
    font-weight: 400;
    font-size: 16px;
    transition: all .4s ease;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    &:focus {
      box-shadow: 0 0 0 2px var(--Ria-orange);
    }
  }
  textarea {
    width: 100%;
    min-height: 150px;
    resize: none;
    // margin-bottom: 50px;
    border: 1px solid rgba(0, 17, 51, 0.15);
    border-radius: 6px;
    padding: 13px;
    color: rgba(0, 17, 51, 0.8);
    font-weight: 400;
    font-size: 16px;
    box-shadow: 0 0 0 0 transparent;
    transition: all .4s ease;
    outline: none;
    &:focus {
      box-shadow: 0 0 0 2px var(--Ria-orange);
    }
  }

  // input[type="submit"] {
  //   margin-top: 50px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 8px 16px;
  //   font-weight: bold;
  //   border-radius: 24px;
  //   font-family: "Nunito Sans";
  //   font-size: 16px;
  //   line-height: 24px;
  //   border: none;   
  //   cursor: pointer;
  //   transition: background 0.3s ease;
  //   height: '48px';
  //   width: 100%;
  //   background: var(--Ria-orange);
  //   color:  white;
  //   border:  1px solid var(--Ria-orange); 
  //   :hover {
  //       background-color: #ff7733;
  //       color: white;
  //   }
  //   @media (max-width: 768px) {
  //       margin-bottom: 16px;
  //   }
  // }
`



const IndexPage = () => {

  const [isSentEmail, setIsSentEmail] = useState({ sentEmail: false, isFailure: false, title:'Página no encontrada 😭', text:'Reintenta o escribenos a ventas@esov.cl' });
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  
  const onSubmit = data => {
  
    const templateParams = {
      reply_to: data.email,
      to_email: data.email,
      to_name: data.fullname,
      to_phone: data.phone,
      from_name: 'ventas@esov.cl',
      message: data.conversation,
    };
   
  emailjs.send('service_zqqqaah', 'template_rp2rv4m', templateParams)
      .then(function(response) {
         console.log('SUCCESS!', response.status, response.text);
         setIsSentEmail({ sentEmail: true, isFailure: false, title:'Gracias 🎉', text:'Responderemos a la brevedad' });
      }, function(error) {
         console.log('FAILED...', error);
         setIsSentEmail({ sentEmail: true, isFailure: false, title:'Página no encontrada 😭', text:'Reintenta o escribenos a ventas@esov.cl' });
      });

    console.log(data)
    // conversation: "test desde la web"
    // email: "mortegac@gmail.com"
    // fullname: "Manuel"
    // phone: "67767676767"
  
  };
  
  
  
  
  return (
  <Layout>
    <Seo title="Empresa" />
    <Title title='Contactanos' secondTitle=' | Cotiza' />
    <Container style={{width:'100%', alignItems: 'flex-start'}}>
      <Container style={{width:'30%'}}>

      { isSentEmail.sentEmail ? (
          <div style={{marginTop: '150px'}}>
            <h2 style={{fontSize: '50px', marginRight: '22px', lineHeight: '60px'}}>{isSentEmail.title}</h2>
            <span style={{marginRight: '22px'}}
            >{isSentEmail.text} 
            </span>
          </div>
      ):(
        <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>          
            <label htmlFor='email'>{'Email'}</label>
            <input
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              type='email'
              name='email'
              id='email'
              // value={state.userEmail}
              />
            <span className='error'>{errors.email?.type === 'required' && "El campo es obligatorio"}</span>

            <label htmlFor='fullname'>{'Nombre:'}</label>
            <input
              {...register("fullname", { required: true, maxLength: 100 })}
              type='text'
              name='fullname'
              id='fullname'/>
            <span className='error'>{errors.fullname?.type === 'required' && "El campo es obligatorio"}</span>

            <label htmlFor='phone'>{'Teléfono:'}</label>
            <input
              {...register("phone", {required: true, minLength: 6, maxLength: 12})}
              type='number'
              name='phone'
              id='phone'/>
            <span className='error'>{errors.phone?.type === 'required' && "Teléfono Invalido"}</span>

          
            <label htmlFor='text-area'>{'Mensaje:'}</label>
            <textarea 
              {...register("conversation", { required: true, minLength: 5 })}
              name="conversation"
              id="conversation"></textarea>
            <span className='error'>{errors.conversation?.type === 'required' && "El campo es obligatorio"}</span>

            {/* <input type="submit" name="Contact Us" value="Contact Us" /> */}
            <input type="submit" id="submit" value="ENVIAR" className="form-controlbutton blue" />
        </FormContainer>
      )}


      </Container>
      <Container style={{width:'70%'}}>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.0490862587058!2d-73.08309062023662!3d-36.76938987945173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzbCsDQ2JzExLjYiUyA3M8KwMDQnNTkuMyJX!5e0!3m2!1ses!2s!4v1412274905071" 
          width="100%" 
          height="725" 
          frameborder="0" 
          scrolling="no" 
          marginheight="0" 
          marginwidth="0" 
          style={{border:0}}>
        </iframe>
      </Container>
    </Container>
  </Layout>
)}

export default IndexPage
